<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div
    class="article-card-item d-block"
    :class="statusClass"
  >
  <div class="article-card-item__box">
    <!-- -------圖片------- -->
    <div class="article-card-item__img">
      <v-img :src="photoUrl" :alt="photoName" :aspect-ratio="500 / 380"></v-img>
    </div>
    <!-- -------標題------- -->
    <div class="article-card-item__info d-flex justify-end align-center">
      <h3
        class="
          article-card-item__info__title
          flex-grow-1
          white--text
          text-center
          text-overflow-ep-1
          tmw-20-10
          ma-auto
        "
      >
        {{ title }}
      </h3>
    </div>
    <!-- -------bg------- -->
    <div class="article-card-item__bg">
    </div>
    <!-- -------Popup------- -->
    <div class="article-card-item__popup px-lg-5 px-6 pt-4 pb-0">
      <h3 class="article-card-item__popup__title mt-lg-0 mb-lg-2 mb-3 mt-5 text-overflow-ep-2">{{ subtitle }}</h3>
      <div v-html="nl2br(content)" class="article-card-item__popup__content secondary--text text-overflow-ep-5">
      </div>
    </div>
    <!-- -------按鈕------- -->
    <div class="article-card-item__btn">
      <TypeD-btn @click="togglePopup" />
    </div>
  </div>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import itemMixins from "./item.js";
export default {
  mixins: [itemMixins],
  data: () => ({
    status: 0,
  }),
  computed: {
    statusClass() {
      if(this.status == 1) return 'article-card-item--active'
      if(this.status == 2) return 'article-card-item--leaveActive'
      return ''
    },
  },
  methods: {
    togglePopup() {
      if(this.status == 2) this.status = 0
      this.status += 1
    },
  },
};
</script>