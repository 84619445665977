/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  props: {
    item: Object
  },
  computed: {
    title() {
      if (!this.item) return ''
      return this.item.title
    },
    subtitle() {
      if (!this.item) return ''
      return this.item.subtitle
    },
    content() {
      if (!this.item) return ''
      return this.item.content
    },
    photoUrl() {
      if (!this.item) return ''
      return this.$helper.getPhotoUrl(this.item.photo)
    },
    photoName() {
      if (!this.item) return ''
      return this.$helper.getPhotoName(this.item.photo)
    },
    buttonText() {
      if (!this.item) return ''
      return this.item.buttonText
    },
    buttonUrl() {
      if (!this.item) return ''
      return this.$helper.modifyUrl(this.item.buttonUrl, this);
    },
    disabledLink() {
      return _isEmpty(this.buttonUrl)
    },
    targetBlank() {
      if (!this.item) return false
      return !!this.item.targetBlank
    },
  },
}